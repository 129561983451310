<template>
	<div class="app-container">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">绩效考核人员</div>
		</div>
		<!--<el-row style="text-align : right;margin-bottom: 20px;" v-if="staffIdStatus != null">

    </el-row>!-->
		<section class="sections-container">
			<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
				<el-form-item label="姓名">
					<el-input v-model="searchForm.staffName" clearable placeholder="输入姓名" style="width: 160px" />
				</el-form-item>
				<el-form-item label="部门">
					<treeselect
						:props="depProps"
						:options="deps"
						:value="searchForm.department"
						:clearable="true"
						:accordion="true"
						:level="searchForm.department"
						@getValue="_getValue($event)"
						style="width: 160px"
					/>
				</el-form-item>
				<el-form-item label="工号">
					<el-input v-model="searchForm.jobNumber" clearable placeholder="输入工号" style="width: 160px" />
				</el-form-item>
				<el-form-item label="手机号">
					<el-input v-model="searchForm.phoneNumber" clearable placeholder="输入手机号" style="width: 160px" />
				</el-form-item>
				<el-form-item label="绩效考核名称">
					<el-select v-model="searchForm.performanceId" filterable clearable placeholder="请选择" style="width: 160px">
						<el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.key"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="searchForm.status" placeholder="选择状态" clearable style="width: 160px">
						<el-option v-for="(item, i) in performanceApproveStatusList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="生成时间">
					<el-date-picker
						type="date"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						v-model="searchForm.startTime"
						placeholder="选择日期时间"
						style="width: 160px"
						clearable
					>
					</el-date-picker>
					<span class="datespan">-</span>
					<el-date-picker
						type="date"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						v-model="searchForm.endTime"
						placeholder="选择日期时间"
						style="width: 160px"
						clearable
					>
					</el-date-picker>
				</el-form-item>
				<el-button type="primary" size="small" icon="el-icon-search" @click="_getList(1)">查询</el-button>
				<el-button type="primary" size="small" icon="el-icon-download" v-if="staffIdStatus != null" @click="_export" style="float: right"
					>导出</el-button
				>
			</el-form>
		</section>
		<section class="sections-container">
			<el-table :data="list" stripe :header-cell-style="tableHeaderColor">
				<el-table-column fixed type="index" align="center" label="序号" width="80"> </el-table-column>
				<el-table-column
					v-for="(col, i) in columns"
					:prop="col.prop"
					:key="i"
					align="center"
					:label="col.label"
					show-overflow-tooltip
					:width="i == 0 ? 280 : ''"
				>
					<template v-slot="{ row }" v-if="col.prop == 'createtime'">
						{{ row.createtime.slice(0, 10) }}
					</template>
					<template v-slot="{ row }" v-else-if="col.prop == 'status'">
						<el-link :underline="false" :style="'cursor: auto;' + _returnStatusColor(row.approveStatus)">{{ row.status }}</el-link>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				v-if="searchForm.totalNum > 0"
				@size-change="_handleSizeChange"
				@current-change="_handleCurrentChange"
				:current-page="searchForm.pageNo"
				:page-sizes="[20, 40, 80, 100]"
				:page-size="searchForm.pageSize"
				style="text-align: right; margin-top: 40px"
				layout="total, sizes, prev, pager, next, jumper"
				:total="searchForm.totalNum"
			>
			</el-pagination>
		</section>
	</div>
</template>

<script>
import treeselect from '@/components/treeSelect/treeSelect';
import { performanceAssessStaffList, performanceNameChoice } from '@/api/performance';
export default {
	components: { treeselect },
	data() {
		return {
			searchForm: {
				staffName: '',
				jobNumber: '',
				phoneNumber: '',
				orgId: '',
				performanceId: String(this.$route.query.assessmentid),
				status: '',
				department: '',
				startTime: '',
				endTime: '',
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			columns: [
				{ label: '绩效考核名称', prop: 'performanceName' },
				{ label: '姓名', prop: 'staffName' },
				{ label: '工号', prop: 'jobNumber' },
				{ label: '手机号码', prop: 'phoneNumber' },
				{ label: '部门', prop: 'orgName' },
				{ label: '职位', prop: 'officeName' },
				{ label: '状态', prop: 'status' },
				{ label: '绩效', prop: 'performanceScoreUser' },
				{ label: '生成时间', prop: 'createtime' }
			],
			deps: this.$store.state.setData.depsList,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			staffPerformanceStatusList: [
				{ label: '进行中', type: 0, val: 'underway' },
				{ label: '未开始', type: 1, val: 'notStarted' },
				{ label: '绩效计划中', type: 2, val: 'inThePlan' },
				{ label: '绩效考评中', type: 3, val: 'evaluation' },
				{ label: '结果确认中', type: 4, val: 'confirmation' },
				{ label: '绩效已公布', type: 5, val: 'publish' }
			],
			list: [],
			options: [],
			performanceApproveStatusList: [],
			staffIdStatus: this.$store.state.app.staffId
		};
	},
	methods: {
		_getValue(eve) {
			this.searchForm.orgId = eve ? eve.id : '';
		},
		_returnStatusColor(status) {
			switch (Number(status)) {
				case 1:
					return 'color:#409EFF';
				case 2:
					return 'color:#FEB840';
				case 3:
					return 'color:#64C940';
				case 4:
					return 'color:#FF604D';
				case 5:
					return 'color:#40CEFC';
				case 6:
					return 'color:#F38A41';
				case 7:
					return 'color:#FF604D';
				case 8:
					return 'color:#F38A41';
				case 9:
					return 'color:#FF604D';
				case 10:
					return 'color:#64C940';

				// case 9: return 'danger';
				// default: return 'success';
			}
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			performanceAssessStaffList(this.searchForm).then((res) => {
				this.list = res.list;
				this.searchForm.totalNum = res.totalNum;
			});
		},
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_export() {
			const outStr = encodeURIComponent(JSON.stringify(this.searchForm));

			const url = `/v1/performance/assess/staff/export?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		tableHeaderColor({ rowIndex }) {
			if (rowIndex === 0) {
				return 'background-color: #F0F8FF;';
			}
		}
	},
	mounted() {
		performanceNameChoice({}).then((res) => {
			this.options = res.list;
		});
		this._getList();
		const mapArr = ['performanceApproveStatus'];

		mapArr.forEach((val) => {
			this[`${val}List`] = this.$store.state.app.dict.filter((item) => item.groupId == val);
		});
	}
};
</script>

<style lang="scss" scoped>
.sections-container {
	overflow: hidden;
	margin-bottom: 20px;
	.title-icon {
		font-size: 16px;
		margin: 0 0 20px 30px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
}
.sections-container {
	border-radius: 4px;
	padding: 20px 40px;
	background-color: #fff;
}
.titlebox {
	text-align: center;
	line-height: 17px;
	background: #fff;
	font: bold 18px/25px 'Microsoft YaHei';
	padding: 14px 40px;
	margin-bottom: 20px;
	> .el-button {
		float: left;
		color: #484848;
		transform: translateY(-8px);
		font-size: 16px;
	}
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
